import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import "../../styles/main.scss"

const Zdmp = () => {
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 600)
  }, [])

  return loader ? (
    <div className="loader" />
  ) : (
    <Layout>
      <SEO
        title="Forcera"
        keywords={[`software development`, `ai`, `big data`, `cloud engineering`, `machine learning`, `data strategy`]}
      />
      <article>
        <div className="container">
          <h2>EZD – Enterprise Integration for Zero Defect Manufacturing</h2>
          <figure>
            <img
             src={"/zdmp.png"}
            />
          </figure>
          <hr />
          <h3>ZDMP</h3>
          <p>
          The <a className="a-blue" href="https://www.zdmp.eu/" target="_blank">ZDMP project</a> tries to establish a digital platform for connected smart factories for achieving excellence in manufacturing through zero-defect processes and products. 
          </p>
          <p>
          This project leverages technological innovations introduced by Industry 4.0 in the digitalization of production processes and the virtual monitoring of product quality to promote the concept of zero defects in digitalized factories, targeting improvements in manufacturing quality and promoting a competitive advantage of European enterprises.
          </p>
          <hr />
          <h3>Enterprise Integration for Zero Defect Manufacturing - EZD</h3>
          <p>
          The Enterprise Integration for Zero Defect Manufacturing (EZD)
          project proposes to develop an intuitive, efficient, and bi-directional integration component between Enterprise Information Systems (EIS), such as Enterprise Resource Planning (ERP) systems, with the ZDMP ecosystem. 
          </p>
          <p>Forcera sub-project will focus on the development of interoperability with the leading brands of ERP systems and validate the developed assets in the context of their integration with the existing ZDMP infrastructure.
          </p>
          <p>
          The ERP is regarded as a top-tier system in the Industry 4.0 concept, making the integration of ERP communication within ZDMP a key feature to enable full automation. Currently, ERP-ZDMP integration is not a straight-forward process, as it is a very time-demanding task that requires hard skills.  
          </p>
          <figure>
            <img
             src={"/zdmp-architecture.png"}
            />
            <figcaption>ZDMP Platform Overall Architecture</figcaption>
          </figure>
          <h3>
            EZD zApp
          </h3>
          <dl>
            <dt>Scope</dt>
            <dd>
              <p>
              EZD aims to be a truly plug-and-play zApp that enables ZDMP users to connect and sync data between EIS and core platform components.
              </p>
            </dd>
            <dt>Main Features</dt>
            <dd>
            <ul className="ul-list">
              <li>ERP endpoint testing and configuration.</li>
              <li>Downstream (ERP>ZDMP) syncing job scheduling and management.</li>
              <li>Upstream (ZDMP>ERP) communication via API.</li>
              <li>Bi-directional communication logging.</li>
            </ul>
            </dd>
          </dl>
          <figure>
            <img
             src={"/image-arch.png"}
            />
            <figcaption>EZD High-Level Architecture and Integration with ZDMP</figcaption>
          </figure>
          <h3>Final Results</h3>
          <p>Our work produced a zApp (EZD) for ZDMP users who want to integrate ERP (Enterprise Resource Planning) software with the main platform. EZD allows fast and intuitive data exchange between the two systems and it doesn’t require hard skills for setup. </p>
          <p>
          This saves ZDMP users time and supports the Industry 4.0 strategy to connect all factory systems into one unified platform. You can watch below how quickly and easly the user can archieve a data exchange flow between an ERP and ZDMP platform.</p>
          <iframe width="720" height="405" src="https://www.youtube.com/embed/fU3BwSQehyU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style={{paddingTop: 10 }}></iframe>
          <p style={{paddingTop: 40 }}> At Forcera, we believe this zApp will contribute to a big boost in automation and interoperability within ZDMP-enabled factory floors. It will support several business use-cases that are of great value for manufacturing companies. </p>
              <figure className="ue-horizon-logo">
              <img
             src={"/horizon-2020.jpeg"}
              />
              <figcaption>This project has received cascading funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement No 825631.</figcaption>
            </figure>
        </div>
      </article>
    </Layout>
  )
}

export default Zdmp;

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`   